<div id="footer-secondaire-lg">
  <div class="d-flex flex-column align-items-center">
    <div class="my-3 footer-secondaire-title">
      SUNLEON
    </div>
    <div class="my-1 text-center version-color">
      Version {{version}}
    </div>
    <div class="my-1 text-center version-color">
      {{versionMoteur}}
    </div>
  </div>
</div>
