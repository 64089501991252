import { AfterViewInit, Component, EventEmitter, inject, OnInit, Output } from '@angular/core'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { TypeReferenceDto } from '../../../sunleon/data-access/http/dto/type-reference-dto'
import { ReferenceService } from '../../../sunleon/data-access/http/reference.service'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { Subject, takeUntil } from 'rxjs'

@Component({
  selector: 'app-form-projet',
  templateUrl: './form-projet.component.html',
  styleUrls: ['./form-projet.component.scss'],
  imports: [NgIf, FormsModule, NgForOf, ReactiveFormsModule, NgClass],
  standalone: true,
})
export class FormProjetComponent implements OnInit, AfterViewInit {
  @Output()
  formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>()

  private readonly geomapDomainService = inject(GeomapDomainService)
  private unsubscribe$ = new Subject<void>()

  projetForm: FormGroup
  dureeContrats: TypeReferenceDto[] = []

  ngAfterViewInit(): void {
    let longitude: number = null
    let latitude: number = null
    this.geomapDomainService
      .getGpsData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((coordinates) => {
        longitude = null
        latitude = null
        if (coordinates) {
          longitude = coordinates[0]
          latitude = coordinates[1]
        }
        this.projetForm.get('longitude').patchValue(longitude === 0 ? null : longitude)
        this.projetForm.get('latitude').patchValue(latitude === 0 ? null : latitude)
      })
  }

  constructor(private referenceService: ReferenceService, private formBuilder: FormBuilder) {
    this.projetForm = this.formBuilder.group({
      nomClient: ['', [Validators.required]],
      nomProjet: ['', [Validators.required]],
      numMonday: [''],
      latitude: [null, [Validators.required]],
      longitude: [null, [Validators.required]],
      departement: [null, [Validators.required, Validators.pattern('^(?!00|20|96|97|98|99)[0-9]{2}$|^(2[AB])$')]],
      dureeContrat: [20, [Validators.required]],
      encadrementProjetNeuf: [false]
    })
  }

  ngOnInit(): void {
    this.referenceService.getDureeContrat().subscribe((data) => {
      this.dureeContrats = data
    })
    this.formReady.emit(this.projetForm)
  }

  onRadioClick(code: string | null, controleName: string) {
    this.projetForm.get(controleName).setValue(code)
  }
}
