<div [formGroup]="projetForm">
  <div class='container'>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Projet</h2>
        <div class="row">
          <div class='col-12'>
          <label for="nomClient" class="form-label">Client  <span class="required">*</span></label>
          <input type="text" class="form-control" id="nomClient" formControlName="nomClient" placeholder="Ex : Marquere Cie" tabindex="1"
                 [ngClass]="projetForm.get('nomClient').invalid && (projetForm.get('nomClient').dirty || projetForm.get('nomClient').touched) ? 'is-invalid' : ''">
          <div class="invalid-feedback">
            <div *ngIf="projetForm.get('nomClient').errors?.['required']">Le client est obligatoire</div>
          </div>
        </div>
        </div>
        <div class="row">
          <div class='col-12'>
          <label for="nomProjet" class="form-label">Nom du projet  <span class="required">*</span></label>
          <input type="text" class="form-control" id="nomProjet" formControlName="nomProjet" placeholder="Ex : 345 - Marquere à Chaponost" tabindex="2"
                 [ngClass]="projetForm.get('nomProjet').invalid && (projetForm.get('nomProjet').dirty || projetForm.get('nomProjet').touched) ? 'is-invalid' : ''">
          <div class="invalid-feedback">
            <div *ngIf="projetForm.get('nomProjet').errors?.['required']">Le projet est obligatoire</div>
          </div>
          </div>
        </div>
        <div class="row">
          <div class='col-12'>
          <label for="numMonday" class="form-label">Id Monday.com</label>
          <input type="text" class="form-control" id="numMonday" formControlName="numMonday" placeholder="Ex : 201" tabindex="3">
        </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Coordonnées GPS</h2>
        <div class="row">
          <div class="col-6">
            <label for="latitude" class="form-label">Latitude <span class="required">*</span></label>
            <input type="number" class="form-control" id="latitude" formControlName="latitude" placeholder="Ex : 48.07997" tabindex="4"
               [attr.disabled]="true"
               [ngClass]="projetForm.get('latitude').invalid && (projetForm.get('latitude').dirty || projetForm.get('latitude').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="projetForm.get('latitude').errors?.['required']">La latitude est obligatoire</div>
              <div *ngIf="projetForm.get('latitude').errors?.['pattern']">La latitude n'est pas valide</div>
            </div>
          </div>
          <div class="col-6">
            <label for="longitude" class="form-label">Longitude <span class="required">*</span></label>
            <input type="number" class="form-control" id="longitude" formControlName="longitude" placeholder="Ex : 0.25298" tabindex="5"
               [attr.disabled]="true"
               [ngClass]="projetForm.get('longitude').invalid && (projetForm.get('longitude').dirty || projetForm.get('longitude').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="projetForm.get('longitude').errors?.['required']">La longitude est obligatoire</div>
              <div *ngIf="projetForm.get('longitude').errors?.['pattern']">La longitude n'est pas valide</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label for="departement" class="form-label">Département <span class="required">*</span></label>
            <input type="text" class="form-control" id="departement" formControlName="departement" placeholder="Ex : 01" tabindex="6"
                   [ngClass]="projetForm.get('departement').invalid && (projetForm.get('departement').dirty || projetForm.get('departement').touched) ? 'is-invalid' : ''">
            <div class="invalid-feedback">
              <div *ngIf="projetForm.get('departement').errors?.['required']">Le département est obligatoire</div>
              <div *ngIf="projetForm.get('departement').errors?.['pattern']">Le département doit être de deux chiffres compris entre 01 et 95 ou 2A ou 2B, excluant 20.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h2 class="card-title">Durée du contrat (ans)<span class="required">*</span></h2>
        <div class="row">
          <div class="col-12">
            <div class="btn-group d-block" role="group">
              <ng-container *ngFor="let dureeContrat of dureeContrats">
                <input type="radio" class="btn-check" name="optionsDureeContrat" [value]="dureeContrat.label" id="{{ dureeContrat.code}}"
                       (click)='onRadioClick(dureeContrat.label, "dureeContrat")' tabindex="31"
                       [checked]="dureeContrat.code === 'VINGTANS'" >
                <label class="btn btn-outline-success" for="{{ dureeContrat.code }}">{{ dureeContrat.label }}</label>
              </ng-container>
            </div>
          </div>
          <input type="hidden" formControlName="dureeContrat" name="dureeContrat"
                 [ngClass]="projetForm.get('dureeContrat').invalid && (projetForm.get('dureeContrat').dirty || projetForm.get('dureeContrat').touched) ? 'is-invalid' : ''">
          <div class="invalid-feedback">
            <div *ngIf="projetForm.get('dureeContrat').errors?.['required']">La durée du contrat est obligatoire</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group form-check my-1">
              <div class="row">
                <div class="col-1">
                  <input class="form-check-input" type="checkbox" formControlName="encadrementProjetNeuf" tabindex="32">
                </div>
                <div class="col-9">
                  <span class="spanCheckbox my-0">Travaux projet neuf</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

