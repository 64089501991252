import { AfterViewInit, ChangeDetectorRef, Component, inject, Input, OnDestroy, ViewChild } from '@angular/core'
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { FormProjetComponent } from '../form-projet/form-projet.component'
import { FormModeValorisationComponent } from '../form-mode-valorisation/form-mode-valorisation.component'
import { OngletEnum } from '../../model/onglet.enum'
import { FormGroup, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms'
import { FormCapexGeneralisesComponent } from '../form-capex-generalises/form-capex-generalises.component'
import { FormCapexTypePoseComponent } from '../form-capex-type-pose/form-capex-type-pose.component'
import { FormZone1Component } from '../form-zone1/form-zone1.component'
import { FormZone2Component } from '../form-zone2/form-zone2.component'
import { FormZone3Component } from '../form-zone3/form-zone3.component'
import { SessionSaveDto } from '../../../sunleon/data-access/http/dto/session-save-dto'
import { SessionZoneSaveDto } from '../../../sunleon/data-access/http/dto/session-zone-save-dto'
import { SessionCommunSaveDto } from '../../../sunleon/data-access/http/dto/session-commun-save-dto'
import { scrollToInvalidElement } from '../../../shared/utils/utils'
import { saveAs } from 'file-saver'
import { RouterLinkWithHref } from '@angular/router'
import { SunleonService } from '../../../sunleon/data-access/http/sunleon.service'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { combineLatestWith, startWith, Subject, takeUntil, tap } from 'rxjs'
import { GeomapDomainService } from '../../domain/geomap.domain'
import { ControlPanelComponent } from '../control-panel/control-panel.component'
import { MeasureDisplayComponent } from '../measure-display/measure-display.component'

@Component({
  selector: 'app-form-parent',
  templateUrl: './form-parent.component.html',
  styleUrls: ['./form-parent.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    NgForOf,
    FormProjetComponent,
    FormModeValorisationComponent,
    FormCapexGeneralisesComponent,
    FormCapexTypePoseComponent,
    FormZone1Component,
    FormZone2Component,
    FormZone3Component,
    FormsModule,
    ControlPanelComponent,
    MeasureDisplayComponent,
    RouterLinkWithHref,
    ReactiveFormsModule,
  ],
})
export class FormParentComponent implements AfterViewInit, OnDestroy {
  @Input()
  bNouvelleSimulation: boolean

  @ViewChild('ngForm')
  ngForm: NgForm

  @ViewChild('contentSuccess')
  modalContentSucces: any

  formIsSubmitting: boolean = false
  formModalIsSubmitting: boolean = false

  ongletActif: OngletEnum
  ongletEnum: typeof OngletEnum
  parentForm: FormGroup

  status: string | undefined
  processIdEnregistrementSimulation: string | undefined
  processIdCreationCsv: string | undefined

  bSol: boolean = false
  bOmbriere: boolean = false
  bToiture: boolean = false

  bZone1Renseigne: boolean = false
  bZone2Renseigne: boolean = false
  bZone3Renseigne: boolean = false

  apiMessageSuccess : string = '';

  private readonly geomapDomainService = inject(GeomapDomainService)
  private currentZoneHasBeanReset$ = this.geomapDomainService.currentZoneHasBeanReset$
  private unsubscribe$ = new Subject<void>()

  constructor(
    private simulationService: SunleonService,
    private sunLeonService: SunleonService,
    private toastr: ToastrService,
    public modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    this.ongletActif = OngletEnum.Projet
    this.ongletEnum = OngletEnum
    this.parentForm = new FormGroup({})
  }

  ngAfterViewInit(): void {
    const observableZone1 = this.parentForm.get('zone1').get('typePoseZone1').valueChanges.pipe(startWith(null))
    const observableZone2 = this.parentForm.get('zone2').get('typePoseZone2').valueChanges.pipe(startWith(null))
    const observableZone3 = this.parentForm.get('zone3').get('typePoseZone3').valueChanges.pipe(startWith(null))

    observableZone1.pipe(combineLatestWith([observableZone2, observableZone3]), tap()).subscribe((values: string[]) => {
      if (values.find((value) => value === 'TOITURE')) {
        this.bToiture = true
      } else {
        this.bToiture = false
      }

      if (values.find((value) => value === 'SOL')) {
        this.bSol = true
      } else {
        this.bSol = false
      }

      if (values.find((value) => value === 'OMBRIERE')) {
        this.bOmbriere = true
      } else {
        this.bOmbriere = false
      }
      
      this.bZone1Renseigne = values[0] !== undefined && values[0] !== null
      this.bZone2Renseigne = values[1] !== undefined && values[1] !== null
      this.bZone3Renseigne = values[2] !== undefined && values[2] !== null
    })

    this.currentZoneHasBeanReset$.pipe(takeUntil(this.unsubscribe$)).subscribe((activeZoneId) => {
      if (activeZoneId <= 2) {
        const listeZones: Record<number, string> = { 0: 'zone1', 1: 'zone2', 2: 'zone3' }
        this.parentForm.get(listeZones[activeZoneId]).reset()
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
  }

  addChildsFormGroup(formChild: FormGroup, controlName: string): void {
    this.parentForm.addControl(controlName, formChild)
    this.cdr.detectChanges()
  }

  saveSimulation(): void {
    if (!this.parentForm.valid) {
      this.parentForm.markAllAsTouched()
      scrollToInvalidElement()
      this.formIsSubmitting = false
      return
    }

    this.formIsSubmitting = true
    const simulationSave = this.getSessionSaveFromForm()

    this.simulationService.transformSession(simulationSave).subscribe({
      next: (data: any) => {
        // Divisez la chaîne en utilisant le caractère "|"
        const elements: string[] = data.message.split('|')
        // Maintenant, elements[0] contient "Premier Élément" et elements[1] contient "Deuxième Élément"
        // Vous pouvez utiliser ces éléments comme vous le souhaitez
        this.processIdEnregistrementSimulation = elements[0]
        this.processIdCreationCsv = elements[1]
        this.monitorProcessStatusSession(this.processIdEnregistrementSimulation, this.processIdCreationCsv)
      },
      error: () => {
        this.formIsSubmitting = false
        this.modalService.dismissAll()
      },
    })
  }

  monitorProcessStatusSession(processIdEnregistrementSimulation: string, processIdCreationCsv: string) {
    this.simulationService.getProcessStatusSession(processIdEnregistrementSimulation).subscribe((result) => {
      this.status = result.status
      if (this.status === 'COMPLETED') {
        this.toastr.info(result.message)
        this.sunLeonService
          .checkSecondProcessStatus(processIdCreationCsv, processIdEnregistrementSimulation)
          .subscribe((result) => {
            this.status = result.status
            if (this.status === 'COMPLETED') {
              if (result.urlCsv != null) {
                saveAs(result.urlCsv, result.nomCsv)
                this.toastr.success(result.message)
                this.apiMessageSuccess = result.message;
                this.openModalSuccess();
                this.formIsSubmitting = false
              }
              this.processIdCreationCsv = undefined
            } else {
              if (this.status === 'FAILED') {
                this.formIsSubmitting = false
                this.toastr.error(result.message)
                this.processIdCreationCsv = undefined
              } else {
                this.toastr.info(result.message)
              }
            }
          })
      } else {
        if (this.status === 'FAILED') {
          this.formIsSubmitting = false
          this.toastr.error(result.message)
          this.processIdEnregistrementSimulation = undefined
        } else {
          this.toastr.info(result.message)
        }
      }
    })
  }

  getSessionSaveFromForm() {
    const formValueProjet = this.parentForm.get('projet').value
    const formValueModeValorisation = this.parentForm.get('modevalorisation').value
    const formValueCapexGeneralises = this.parentForm.get('capexgeneralises').value
    const formValueCapexTypePose = this.parentForm.get('capextypepose').value
    const formValueZone1 = this.parentForm.get('zone1').value
    const formValueZone2 = this.parentForm.get('zone2').value
    const formValueZone3 = this.parentForm.get('zone3').value

    const zoneDto: SessionZoneSaveDto[] = []
    const communDto: SessionCommunSaveDto = {
      nomClient: formValueProjet.nomClient,
      nomProjet: formValueProjet.nomProjet,
      numMonday: formValueProjet.numMonday,
      latitude: formValueProjet.latitude,
      longitude: formValueProjet.longitude,
      departement: formValueProjet.departement,
      dureeContrat: formValueProjet.dureeContrat,

      modeValorisation: formValueModeValorisation.modeValorisation,
      puissanceSouscriteKVA: formValueModeValorisation.puissanceSouscriteKVA,
      travauxDistanceVoirieVrd:
        formValueModeValorisation.travauxDistanceVoirieVrd === null
          ? 0
          : formValueModeValorisation.travauxDistanceVoirieVrd,
      prm: formValueModeValorisation.prm,
      tarifHch: formValueModeValorisation.tarifHch,
      tarifHph: formValueModeValorisation.tarifHph,
      tarifHpt: formValueModeValorisation.tarifHpt,
      tarifHpb: formValueModeValorisation.tarifHpb,
      tarifHcb: formValueModeValorisation.tarifHcb,
      distanceRaccordementPublic: formValueModeValorisation.distanceRaccordementPublic,
      dispositifNonInjection: formValueModeValorisation.dispositifNonInjection,
      dispoTGBT: formValueModeValorisation.dispoTGBT,
      automate: formValueModeValorisation.automate,

      typeGammePanneau: formValueCapexGeneralises.typeGammePanneau,
      typeGammeOnduleur: formValueCapexGeneralises.typeGammeOnduleur,
      coffretDc: formValueCapexGeneralises.coffretDc,
      paratonnerre: formValueCapexGeneralises.paratonnerre,
      aerodrome: formValueCapexGeneralises.aerodrome,
      prixApportAffaireHtWc:
        formValueCapexGeneralises.prixApportAffaireHtWc === null ? 0 : formValueCapexGeneralises.prixApportAffaireHtWc,
      travauxDiversHT:
        formValueCapexGeneralises.travauxDiversHT === null ? 0 : formValueCapexGeneralises.travauxDiversHT,
      stationMeteo: formValueCapexGeneralises.stationMeteo,
      deposeCandelabre: formValueCapexTypePose.deposeCandelabre === null ? 0 : formValueCapexTypePose.deposeCandelabre,
      poseCandelabre: formValueCapexTypePose.poseCandelabre === null ? 0 : formValueCapexTypePose.poseCandelabre,
      eclairageSousOmbriere: formValueCapexTypePose.eclairageSousOmbriere,
      nbAbattageArbres: formValueCapexTypePose.nbAbattageArbres === null ? 0 : formValueCapexTypePose.nbAbattageArbres,
      mlClotureSol: formValueCapexTypePose.mlClotureSol === null ? 0 : formValueCapexTypePose.mlClotureSol,
      nbPortailsSol: formValueCapexTypePose.nbPortailsSol === null ? 0 : formValueCapexTypePose.nbPortailsSol,
      mlGardeCorpsProvisoire: formValueCapexTypePose.mlGardeCorpsProvisoire === null ? 0 : formValueCapexTypePose.mlGardeCorpsProvisoire,
      mlLigneDeVie: formValueCapexTypePose.mlLigneDeVie === null ? 0 : formValueCapexTypePose.mlLigneDeVie,
      nbEchelleCrinoline: formValueCapexTypePose.nbEchelleCrinoline === null ? 0 : formValueCapexTypePose.nbEchelleCrinoline,
      modeDebug: false,
      forcerSoulteEtRenteInialeVT:false,
      encadrementProjetNeuf: formValueProjet.encadrementProjetNeuf
    }

    zoneDto.push({
      id: 1,
      typePose: formValueZone1.typePoseZone1,
      surface: formValueZone1.surfaceZone1,
      azimut: formValueZone1.azimutZone1,
      typeToiture: formValueZone1.typeToitureZone1 === '' ? null : formValueZone1.typeToitureZone1,
      pvReady: formValueZone1.pvReadyZone1,
      inclinaison: formValueZone1.inclinaisonZone1,
      typeVehiculeParking:
        formValueZone1.typeVehiculeParkingZone1 === '' ? null : formValueZone1.typeVehiculeParkingZone1,
      typePlaceParking: formValueZone1.typePlaceParkingZone1 === '' ? null : formValueZone1.typePlaceParkingZone1,
    })

    if (formValueZone2.typePoseZone2 !== null) {
      zoneDto.push({
        id: 2,
        typePose: formValueZone2.typePoseZone2,
        surface: formValueZone2.surfaceZone2,
        azimut: formValueZone2.azimutZone2,
        typeToiture: formValueZone2.typeToitureZone2 === '' ? null : formValueZone2.typeToitureZone2,
        pvReady: formValueZone2.pvReadyZone2,
        inclinaison: formValueZone2.inclinaisonZone2,
        typeVehiculeParking:
          formValueZone2.typeVehiculeParkingZone2 === '' ? null : formValueZone2.typeVehiculeParkingZone2,
        typePlaceParking: formValueZone2.typePlaceParkingZone2 === '' ? null : formValueZone2.typePlaceParkingZone2,
      })
    }

    if (formValueZone3.typePoseZone3 !== null) {
      zoneDto.push({
        id: 3,
        typePose: formValueZone3.typePoseZone3,
        surface: formValueZone3.surfaceZone3,
        azimut: formValueZone3.azimutZone3,
        typeToiture: formValueZone3.typeToitureZone3 === '' ? null : formValueZone3.typeToitureZone3,
        pvReady: formValueZone3.pvReadyZone3,
        inclinaison: formValueZone3.inclinaisonZone3,
        typeVehiculeParking:
          formValueZone3.typeVehiculeParkingZone3 === '' ? null : formValueZone3.typeVehiculeParkingZone3,
        typePlaceParking: formValueZone3.typePlaceParkingZone3 === '' ? null : formValueZone3.typePlaceParkingZone3,
      })
    }

    return {
      commun: communDto,
      zones: zoneDto,
    } as SessionSaveDto
  }

  onButtonProjetClick() {
    this.ongletActif = OngletEnum.Projet
    this.geomapDomainService.disableTools()
    this.geomapDomainService.deselectionBarreOutils()
    this.setAucunDessin()
  }

  onButtonModeValorisationClick() {
    this.ongletActif = OngletEnum.ModeValorisation
    this.setZone(3)
    this.geomapDomainService.selectionBarreOutils()
    this.geomapDomainService.deselectionDessinOutilsSeulementZone()
  }

  onButtonOptionsCapexClick() {
    this.ongletActif = OngletEnum.OptionsCapex
    this.geomapDomainService.disableTools()
    this.geomapDomainService.deselectionBarreOutils()
    this.setAucunDessin()
  }

  onButtonCapexTypePoseClick() {
    this.ongletActif = OngletEnum.CapexTypePose
    this.geomapDomainService.disableTools()
    this.geomapDomainService.deselectionBarreOutils()
    this.setAucunDessin()
  }

  onButtonZone1Click() {
    this.ongletActif = OngletEnum.Zone1
    this.setZone(0)
  }
  onButtonZone2Click() {
    this.ongletActif = OngletEnum.Zone2
    this.setZone(1)
  }
  onButtonZone3Click() {
    this.ongletActif = OngletEnum.Zone3
    this.setZone(2)
  }

  /* OUTILS DESSINS */

  setZone(zoneIndex: number): void {
    this.geomapDomainService.setZone(zoneIndex)
    this.geomapDomainService.selectionBarreOutils()
    this.geomapDomainService.selectionDessinOutilsSeulementZone()
  }

  setAucunDessin(): void {
    this.geomapDomainService.deselectionDessinOutilsSeulementZone()
    this.geomapDomainService.hiddenCurrentZone()
  }

  openModalSuccess() {
    console.log("test")
    this.modalService
      .open(this.modalContentSucces, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false })
      .result.then(
      () => {
        this.apiMessageSuccess = '';
        // Traitement à effectuer lorsque le modal est fermé (si nécessaire)
        this.formModalIsSubmitting = false
      },
      () => {
        // Traitement à effectuer si le modal est fermé avec un rejet (si nécessaire)
        this.apiMessageSuccess = '';
        this.formModalIsSubmitting = false
      }
    )
  }

}


